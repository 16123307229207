<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          :id="block_id"
          v-click-outside="triggerOnFocus"
          class="statistics-box pa-4"
          :class="{'blocked-box' : blocked_visibility}"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="font-weight-bold d-flex align-center mt-2">
                Транспортные средства
              </div>
            </v-col>
            <v-col cols="4">
              <label>
                Цель перемещения
                <v-autocomplete
                  v-model="about_transport.transports_purpose_code"
                  :items="codes"
                  auto-select-first
                  item-text="txt"
                  item-value="val"
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  class="mr-1 p-0"
                  @change="changeVisibility(about_transport.transports_purpose_code)"
                />
              </label>
            </v-col>

            <v-col
              cols="8"
            >
              <label>Транспортные средства, ввозимые с освобождением от уплаты таможенных пошлин, налогов (ввоз со льготой)</label>
              <v-radio-group
                v-model="about_transport.benefit"
                hide-details="true"
                class="mt-2"
                value="radio-2"
                @change="readyToUpdate"
              >
                <v-radio
                  class="mb-0"
                  label="Да"
                  :value="true"
                  color="#5CB7B1"
                />
                <v-radio
                  class="mb-0"
                  label="Нет"
                  :value="false"
                  color="#5CB7B1"
                />
              </v-radio-group>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import blockVisibility from '@/mixins/block-visibility';

export default {
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, blockVisibility],
  data: () => ({
    block_id: "passenger-about-transport",
    about_transport: {
      declaration_id: null,
      benefit: "",
      transports_purpose_code: ""
    },
    codes: [
      {val:'1', txt:'Ввоз (свободное обращение)'},
      // {val:'2', txt:'Временный ввоз'},
      {val:'3', txt:'Вывоз'},
      {val:'4', txt:'Временный вывоз'},
      {val:'5', txt:'Транзит'}
    ]
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      user: "auth/getUser",
      wares: "ptd/getSelectedWares"
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    }
  },
  methods: {
    changeVisibility(val){
      this.$emit('changeVisibility', val)
    },
    readyToUpdate(){
      this.hasChanges = true
      this.fields_been_visited = true
    },
    setFields() {
      const about_transport = this.selected.about_transport;

      Object.keys(this.about_transport).forEach((key) => {
        if (key in about_transport) {
          this.about_transport[key] = about_transport[key];
        }
      })
    },
    uploadData() {
      const about_transport = this.convertEmptyStringsToNull({
        ...this.about_transport
      })

      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "about_transport",
        value: about_transport,
      });
    },
  }
};
</script>
