<template>
  <v-container
    fluid
    :class="{'blocked-box' : blocked_visibility}"
  >
    <v-row class="mb-1">
      <v-col
        cols="2"
        class="d-flex align-center"
      >
        <span class="font-weight-bold">Транспортные средства ({{ transports_json.length }})</span>
      </v-col>
    </v-row>
    <div class="rs-goods-list">
      <transport-item
        v-for="(item, idx) in transports_json"
        :key="'transport-items-' + idx"
        ref="goodsItem"
        :item="item"
        :index="idx"
        :all-transport-items="transports_json"
        :transport="transport"
        :nsi-transport-marks="nsiTransportMarks"
      />
    </div>

    <v-col
      cols="12"
      class="px-0 d-flex justify-space-between align-end"
    >
      <v-btn
        color="#5CB7B1"
        :disabled="inProcess"
        @click="addTransport"
      >
        <v-icon>mdi-plus</v-icon> Добавить транспортное средство
      </v-btn>
    </v-col>
  </v-container>
</template>

<script>
import TransportItem from './transport/transport-item.vue'
import { mapGetters } from "vuex";
import { eventBus } from "@/bus.js"
import blockVisibility from '@/mixins/block-visibility';

export default {
  components: {
    TransportItem,
  },
  mixins: [blockVisibility],
  data:() => ({
    inProcess:false,
    transport: {
      declaration_id: null
    },
    transports_json: [],
    nsiTransportMarks: [],
  }),
  computed: {
    ...mapGetters({
      selected: 'ptd/getSelected',
      user: "auth/getUser"
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  // created() {
  //   this.loadTransportMarks()
  // },
  // beforeDestroy(){
  //   openGoodsModal.unsubscribe()
  // },
  created(){
    this.loadTransportMarks()
  },
  methods: {
    loadTransportMarks(){
      this.$store.dispatch('catalogs/fetchNsiTransportMarks').then((items) => {
        this.nsiTransportMarks = items
      }).catch(() => {
        this.$snackbar({text: "Ошибка загрузки справочника",color:"red", top:false, right: false});
      })
    },
    setFields() {
      const transport = this.selected.transport
      Object.keys(this.transport).forEach((key) => {
        if (key in transport) {
          this.transport[key] = transport[key];
        }
      })
      this.transports_json = this.selected.transport.transports_json.length ? [...this.selected.transport.transports_json] : []
    },
    addTransport(){
      this.transports_json.push({
        releaseDate: null,
        countryLetter: null,
        invoiceCost: null,
        trailer: null,
        volume: null,
        number: null,
        invoiceCurrencyLetter: null,
        transportMarkCode: null,
        vinType: null,
        transportMarkName: null,
        vehicleTypeCode: null,
        vin: null,
        countryName: null,
        unregistered: null,
      })
      const transport = {
        ...this.transport,
        transports_json: [
          ...this.transports_json,
        ],
      }
      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "transport",
        value: transport,
      }).then(() => {
        this.$snackbar({text:"Добавлено [Транспортные средства]",color:"green", top:false, right: false});
      }).finally(() => {
        const idx = this.transports_json.length - 1
        eventBus.$emit("openLastGood", idx)
      })
    },
    updateItem(idx, item){
      this.transports_json[idx] = item
      this.uploadData()
    },
    uploadData() {
      const transport = {
        ...this.transport,
        transports_json: [
          ...this.transports_json,
        ],
      }
      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "transport",
        value: transport,
      }).then(() => {
        this.$snackbar({text:"Обновлено [Транспортные средства]",color:"green", top:false, right: false});
      })
    },
    // loadTransportMarks(){
    //   this.$store.dispatch('catalogs/fetchNsiTransportMarks').then((items) => {
    //     this.nsiTransportMarks = items
    //   }).catch(() => {
    //     this.$snackbar({text: "Ошибка загрузки справочника",color:"red", top:false, right: false});
    //   })
    // },
    // addGoodsItem() {
    //   this.inProcess = true
    //   this.$store.dispatch("ptd/addNewGoodsItem").then(() => {
    //     console.log(this.wares)
    //     // const tabIndexButtons = document.querySelectorAll('.ware-item-toggle')
    //     // tabIndexButtons[tabIndexButtons.length - 1].focus()
    //     this.inProcess = false
    //   }).catch(() => {
    //     this.$snackbar({text:'Не удалось создать товар', color:'red', top:false, right: false})
    //     this.inProcess = false
    //   })
    // },
  },
};
</script>
