<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <div
      class="statistics-box pa-4 mb-6"
      :class="{'blocked-box' : blocked_visibility}"
    >
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <div class="font-weight-bold d-flex align-center mt-2">
            Товары
          </div>
        </v-col>
        <v-col cols="4">
          <label>
            Цель перемещения
            <v-autocomplete
              v-model="about_wares.wares_purpose_code"
              :items="codes"
              auto-select-first
              item-text="txt"
              item-value="val"
              outlined
              hide-details="auto"
              dense
              background-color="white"
              class="mr-1 p-0"
              @change="changeVisibility(about_wares.wares_purpose_code)"
            />
          </label>
        </v-col>

        <v-col
          cols="2"
          offset="2"
        >
          <label>
            Общая сумма
            <v-text-field
              v-model="about_wares_json.invoice_cost"
              outlined
              background-color="white"
              dense
              hide-details="auto"
            />
          </label>
        </v-col>
        <v-col cols="2">
          <label>
            Код валюты
            <v-autocomplete
              v-model="about_wares_json.invoice_currency_letter"
              auto-select-first
              :items="currencies"
              item-text="text"
              item-value="letterCode"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :filter="filterBySearchField"
            />
          </label>
        </v-col>
        <v-col cols="2">
          <label>
            Вес нетто
            <v-text-field
              disabled
              background-color="grey lighten-2"
              outlined
              dense
              hide-details="auto"
              suffix="кг"
              :value="totalWeight"
            />
          </label>
        </v-col>
        <!--<v-col cols="2">
          <label>
            Количество товара
            <v-text-field
              disabled
              background-color="grey lighten-2"
              outlined
              dense
              hide-details="auto"
              :value="totalQuantity"
            />
          </label>
        </v-col>-->
      </v-row>
    </div>
    <v-row v-show="about_wares.wares_purpose_code">
      <v-col>
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Товары, ввозимые с освобождением от уплаты таможенных пошлин, налогов (ввоз со льготой)</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="about_wares_json.benefit"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col>
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Товары, стоимость, вес и (или) количество которых превышают нормы ввоза без уплаты таможенных пошлин, налогов</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="about_wares_json.over_standard"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col>
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Культурные ценности</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="about_wares_json.cultural_property"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col>
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Гражданское и служебное оружие, его основные (составные) части, патроны к нему</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="about_wares_json.guns"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col>
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Наркотические средства, психотропные вещества, их прекурсоры в виде лекарственных средств</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="about_wares_json.drugs"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
    </v-row>

    <!--  -->

    <v-row v-show="about_wares.wares_purpose_code">
      <v-col>
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Животные, растения</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="about_wares_json.wild_life"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col>
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Коллекционные материалы по минералогии, палеонтологии, кости ископаемых животных</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="about_wares_json.collections"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col>
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Образцы биологических материалов человека</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="about_wares_json.biologic_material"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col>
        <div class="radio-card">
          <div class="pa-4 radio-card-body">
            <label>Другие товары, в отношении которых подлежат соблюдению запреты и ограничения и требуется представление подтверждающих документов и (или) сведений</label>
          </div>
          <v-divider />
          <v-radio-group
            v-model="about_wares_json.under_ban"
            hide-details="true"
            class="pa-4"
            @change="readyToUpdate"
          >
            <v-radio
              class="mb-0"
              label="Да"
              :value="true"
              color="#5CB7B1"
            />
            <v-radio
              class="mb-0"
              label="Нет"
              :value="false"
              color="#5CB7B1"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import blockVisibility from '@/mixins/block-visibility';

export default {
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, blockVisibility],
  data: () => ({
    block_id: "passenger-about-wares",
    about_wares: {
      declaration_id: null,
      wares_purpose_code: ""
    },
    about_wares_json: {
      benefit: "",
      biologic_material: "",
      collections: "",
      cultural_property: "",
      drugs: "",
      guns: "",
      over_standard: "",
      under_ban: "",
      wild_life: "",
      invoice_cost: "",
      invoice_currency_letter: ""
    },
    codes: [
      {val:'1', txt:'Ввоз (свободное обращение)'},
      // {val:'2', txt:'Временный ввоз'},
      {val:'3', txt:'Вывоз'},
      {val:'4', txt:'Временный вывоз'},
      {val:'5', txt:'Транзит'}
    ]
    // arr.every( v => v === arr[0] )
  }),
  computed: {
    ...mapGetters({
      selected: "ptd/getSelected",
      wares: "ptd/getSelectedWares",
      currencies: "catalogs/getNsiCurrencies"
    }),
    totalCost(){
      if(!this.wares.length) return ''
      const equal_currency = this.wares.every( i => i.invoice_currency_letter === this.wares[0].invoice_currency_letter )
      if(equal_currency){
        let total = 0
        total = this.wares.reduce((a, b) => { return a + b.invoice_cost }, 0)
        return `${total}`
      }else{
        return ''
      }
    },
    totalCurrency(){
      if(!this.wares.length) return ''
      const equal_currency = this.wares.every( i => i.invoice_currency_letter === this.wares[0].invoice_currency_letter )
      if(equal_currency){
        return `${this.wares[0].invoice_currency_letter}`
      }else{
        return ''
      }
    },
    totalWeight(){
      if(!this.wares.length) return ''
      return this.wares.reduce((a, b) => { return a + b.net_weight }, 0)
    },
    totalQuantity(){
      if(!this.wares.length) return ''
      const equal_measure = this.wares.every( i => i.measure_unit_digit === this.wares[0].measure_unit_digit )
      if(equal_measure){
        let total = 0
        total = this.wares.reduce((a, b) => { return a + b.measure_unit_quantity }, 0)
        return `${total} ${this.wares[0].measure_unit_letter}`
      }else{
        return ''
      }
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    }
  },
  methods: {
    changeVisibility(val){
      this.$emit('changeVisibility', val)
    },
    readyToUpdate(){
      this.hasChanges = true
      this.fields_been_visited = true
    },
    setFields() {
      const about_wares = this.selected.about_wares;
      const about_wares_json = this.selected.about_wares.about_wares_json;

      Object.keys(this.about_wares).forEach((key) => {
        if (key in about_wares) {
          this.about_wares[key] = about_wares[key];
        }
      });

      Object.keys(this.about_wares_json).forEach((key) => {
        if (key in about_wares_json) {
          this.about_wares_json[key] = about_wares_json[key];
        }
      });
    },
    uploadData() {
      const about_wares = this.convertEmptyStringsToNull({
        ...this.about_wares,
        about_wares_json: {
          ...this.about_wares_json,
        },
      })

      return this.$store.dispatch("ptd/uploadBlockData", {
        name: "about_wares",
        value: about_wares,
      });
    },
  }
};
</script>

<style scoped>
.radio-card{
    background-color: #ededed;
    border-radius: 5px;
}
.radio-card-body{
    min-height: 158px;
    font-size: 14px !important;
}
</style>
